import { promisify } from "./utils/Promisify";

function printFnDescriptor(data?: any) {
  return {
    type: "PRINT" as const,
    payload: {
      data,
    },
  };
}

export type TPrintArgs = Parameters<typeof printFnDescriptor>;
export type TPrintDescription = ReturnType<typeof printFnDescriptor>;
export type TPrintActionType = TPrintDescription["type"];

async function print(...args: Parameters<typeof printFnDescriptor>) {
  return promisify(printFnDescriptor)(...args);
}

export default print;
