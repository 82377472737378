import ExecutionMetaData from "./utils/ExecutionMetaData";
import type { TDefaultMessage } from "utils/MessageUtil";
import { dataTreeEvaluator } from "../handlers/evalTree";
import TriggerEmitter, { BatchKey } from "./utils/TriggerEmitter";
import CryptoJS from "crypto-js";
// import log from "loglevel";
import { promisify } from "./utils/Promisify";

// const SECRECT_KEY = "abcd";
// const EncryptedStr = (params: string) => {
//   // 使用 AES 加密
//   const encrypted = CryptoJS.AES.encrypt(params, SECRECT_KEY).toString();
//   return encrypted;
// };
// const DecryptedStr = (params: string) => {
//   // 使用 AES 解密
//   const decrypted = CryptoJS.AES.decrypt(params, SECRECT_KEY).toString(
//     CryptoJS.enc.Utf8,
//   );
//   return decrypted;
// };

function getDomain(str: string) {
  const regex = /\$\$(.*?)\$\$/g;
  const matches = [];
  let match;
  while ((match = regex.exec(str)) !== null) {
    matches.push(match[1]);
  }
  return matches[0];
}

export let messageEventMap = new Map<
  number | string,
  (event: MessageEvent<any>) => void
>();

function windowMessageListenerFnDescriptor(
  this: any,
  domain?: string,
  callback?: (...args: any) => any,
) {
  return {
    type: "WINDOW_MESSAGE_LISTENER" as const,
    payload: {
      domain,
      listenerId: this.listenerId,
    },
  };
}

function unlistenWindowMessageFnDescriptor(this: any, domain: string) {
  return {
    type: "UNLISTEN_WINDOW_MESSAGE" as const,
    payload: {
      domain,
    },
  };
}

export type TWindowMessageListenerArgs = Parameters<
  typeof windowMessageListenerFnDescriptor
>;
export type TWindowMessageListenerDescription = ReturnType<
  typeof windowMessageListenerFnDescriptor
>;

export type TUnlistenWindowMessageDescription = ReturnType<
  typeof unlistenWindowMessageFnDescriptor
>;

export type TWindowMessageListenertActionType =
  TWindowMessageListenerDescription["type"];

export function WindowMessageListener(...args: TWindowMessageListenerArgs) {
  const metaData = ExecutionMetaData.getExecutionMetaData();
  const [domain, callback]: any = args;
  const _CB_ = CryptoJS.MD5(callback?.toString()).toString().slice(0, 8);

  let listenerId = `WML_${_CB_}_$$${domain}$$`;
  const isRunningListener = messageEventMap.get(listenerId);
  if (!domain) {
    console.warn("请指定需要监听的网址");
    throw new Error(`请指定需要监听的网址`);
  }
  if (domain === "*") {
    throw new Error(`此参数中不允许使用“*”通配符，需要特定的网址`);
  }
  if (isRunningListener) {
    return;
  }
  // console.log(
  //   "\n[[ MESSAGE ]]\t[worker] (start) self 需要添加+监听器",
  //   `${listenerId}`,
  // );
  TriggerEmitter.emit(BatchKey.process_batched_triggers, {
    trigger: windowMessageListenerFnDescriptor.apply({ listenerId }, args),
    ...metaData,
  });
  const messageHandler = (event: MessageEvent<TDefaultMessage<any>>) => {
    const message = event.data;
    if (message.messageId !== listenerId) return;
    ExecutionMetaData.setExecutionMetaData(metaData);
    const { body } = message;
    if (!dataTreeEvaluator) throw new Error("No data tree evaluator found");
    ExecutionMetaData.setExecutionMetaData(metaData);
    self["$isDataField"] = false;
    if (body.data) {
      // console.log(`[[ MESSAGE ]]\t[worker] (end ) self 执行 callback`);
      if (typeof callback === "function") callback(body.data);
    } else {
      self.removeEventListener("message", messageHandler);
    }
  };
  messageEventMap.set(listenerId, messageHandler);
  self.addEventListener("message", messageHandler);
}

export async function unlistenWindowMessage(domain: string) {
  if (!domain) {
    throw new Error(`请指定需要取消监听的网址`);
  }
  // console.log(
  //   `\n[[ MESSAGE ]]\t[worker] (start) self 需要取消-对${domain}的监听`,
  // );
  const executor = promisify(unlistenWindowMessageFnDescriptor);
  let response;
  try {
    response = await executor(domain);
    messageEventMap.forEach((cb, mk: any) => {
      const _domain = getDomain(mk);
      if (_domain === domain) {
        const currentListenerHandler = messageEventMap.get(mk);
        if (currentListenerHandler) {
          self.removeEventListener("message", currentListenerHandler);
          messageEventMap.delete(mk);
          // console.log("[[ MESSAGE ]]\t[worker] (end )  监听器已取消", mk);
        }
      }
    });
  } catch (e) {
    throw e;
  }
  return response;
}
